<template>
    <div
        class="settings-page dF fC pb-5"
        :class="$mq == 'sm' ? 'px-4' : 'container container-2'"
    >
        <div class="mb-3" style="font-size: 18px">
            <a-icon type="arrow-left" class="mr-3" @click="$router.go(-1)" />
            <b>{{instance.name}}</b>
        </div>
        <a-form class="profile">
            <bh-loading :show="loading" />
            <div>
                <a-card>
                    <h6 class="mb-4 text-dark">Project Profile</h6>
                    <hr style="margin-left: -24px; margin-right: -24px" />
                    <a-form-model
                        ref="ruleForm"
                        :model="instance"
                    >
                        <div :class="$mq == 'sm' ? '' : 'dF'">
                            <div
                                style="order: 2"
                                :style="
                                    $mq == 'sm' ? 'width:100%' : 'width:25%'
                                "
                            >
                                <div class="hexagon-main">
                                    <div class="hexagon hexagon2">
                                        <div class="hexagon-in1">
                                            <div
                                                @click="
                                                    colourInstance(instance)
                                                "
                                                class="hexagon-in2"
                                                :style="{
                                                    'background-color':
                                                        getBackground(instance),
                                                }"
                                            ></div>
                                        </div>
                                    </div>
									<div class="text-center">
										<strong>{{ instance.readableId || '' }}</strong>
										<br/>
                                        <strong>{{ instance.name }}</strong>
                                    </div>
                                </div>
                            </div>
                            <div
                                :class="$mq == 'sm' ? 'mt-3' : ''"
                                :style="
                                    $mq == 'sm' ? 'width:100%' : 'width:75%'
                                "
                            >
								<a-row :gutter="16">
                                    <a-col :span="8">
                                        <a-form-model-item
                                            label="Project ID"
                                        >
                                            <a-input
                                                :value="instance.readableId"
                                                placeholder="Project ID"
                                                size="large"
                                                disabled
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="8">
                                        <a-form-model-item
                                            label="Project Name"
                                            prop="name"
											:rules="req('Please enter the project name')"
                                        >
                                            <a-input
                                                v-model="instance.name"
                                                placeholder="Project Name"
                                                size="large"
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
									<a-col :span="8">
                                        <a-form-model-item
                                            prop="marketingName"
                                        >
											<template slot="label">
												Project Marketing Name
												<a-tooltip overlayClassName="change-tooltip-color" title="Fill in this field if you need a forward-facing name that end users will see for your project.">
													<a-icon type="question-circle" style="font-size: 14px" />
												</a-tooltip>
											</template>
                                            <a-input
                                                v-model="instance.marketingName"
                                                placeholder="Project Marketing Name"
                                                size="large"
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
									<a-col :span="8" v-if="instance.partner">
                                        <a-form-model-item label="Partner" >
                                            <a-input
                                                :value="instance.partner.companyName"
                                                placeholder="Partner"
                                                size="large"
                                                disabled
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
                                </a-row>
								<a-row :gutter="16">
	                                <a-col :span="8">
	                                    <a-form-model-item
	                                        ref="projectType"
	                                        label="Project Type"
	                                        prop="projectType"
	                                    >
	                                        <a-input
	                                            :value="instance.projectType"
	                                            placeholder="Project Type"
	                                            size="large"
	                                            disabled
	                                        >
	                                        </a-input>
	                                    </a-form-model-item>
	                                </a-col>
	                                <a-col :span="8">
	                                    <a-form-model-item
	                                        ref="productType"
	                                        label="Product Type"
	                                        prop="productType"
	                                    >
	                                        <a-input
	                                            :value="
	                                                instance.productType ===
	                                                'lowrise'
	                                                    ? 'Low Rise'
	                                                    : 'High Rise'
	                                            "
	                                            placeholder="Product Type"
	                                            size="large"
	                                            disabled
	                                        >
	                                        </a-input>
	                                    </a-form-model-item>
	                                </a-col>
	                                <a-col :span="8">
	                                    <a-form-model-item
	                                        ref="status"
	                                        label="Project Status"
	                                        prop="status"
	                                    >
	                                        <a-input
	                                            :value="projectStatus"
	                                            placeholder="Project Status"
	                                            size="large"
	                                            disabled
	                                        >
	                                        </a-input>
	                                    </a-form-model-item>
	                                </a-col>
	                            </a-row>
								<a-row :gutter="16">
									<a-col :span="8">
                                        <a-form-model-item
                                            required
                                            prop="billingName"
                                            :rules="req('Please enter the Billing Name')"
                                        >
											<template slot="label">
												Billing Name
												<a-tooltip overlayClassName="change-tooltip-color" title="The 'Billing Name' is what will appear on the invoice.">
													<a-icon type="question-circle" style="font-size: 14px" />
												</a-tooltip>
											</template>
                                            <a-input
                                                v-model="instance.billingName"
                                                placeholder="Billing Name"
                                                size="large"
                                                required
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="8">
                                        <a-form-model-item
                                            label="Address"
                                            prop="address"
											:rules="req('Please enter the address')"
                                        >
                                            <a-input
                                                v-model="instance.address"
                                                placeholder="Address"
                                                size="large"
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="8">
                                        <a-form-model-item
                                            label="City"
                                            prop="city"
											:rules="req('Please enter the city name')"
                                        >
                                            <a-input
                                                v-model="instance.city"
                                                placeholder="Enter a city name"
                                                size="large"
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
                                </a-row>
                                <a-row :gutter="16">
                                    <a-col :span="8">
                                        <a-form-model-item
                                            prop="region"
                                            label="Province/State"
											:rules="req('Please enter the Province/State name')"
                                        >
                                            <a-input
                                                v-model="instance.region"
                                                placeholder="Enter a Province/State name"
                                                size="large"
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="8">
                                        <a-form-model-item
                                            prop="postalCode"
                                            label="Zip/Postal Code"
											:rules="req('Please enter the Zip/Postal Code')"
                                        >
                                            <a-input
                                                v-model="instance.postalCode"
                                                placeholder="Enter a Postal Code"
                                                size="large"
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="8">
                                        <a-form-model-item
                                            prop="country"
                                            label="Country"
											:rules="req('Please select country')"
                                        >
                                            <a-select
                                                v-model="instance.country"
                                                placeholder="Country"
                                                size="large"
                                            >
                                                <a-select-option value="US"
                                                    >United
                                                    States</a-select-option
                                                >
                                                <a-select-option value="CA"
                                                    >Canada</a-select-option
                                                >
                                            </a-select>
                                        </a-form-model-item>
                                    </a-col>
                                </a-row>
                                <a-row
                                    :gutter="16"
                                    v-if="
                                        storeInstances && storeInstances.length
                                    "
                                >
                                    <a-col :span="24">
                                        <a-divider orientation="left"
                                            >Link Other Child
                                            Projects</a-divider
                                        >
                                        <div class="dF aC" style="gap: 20px">
                                            <div>
                                                Link child projects to the
                                                parent project
                                            </div>
                                            <a-select
                                                mode="multiple"
                                                size="large"
                                                placeholder="Please select"
                                                v-model="children"
                                                style="width: 500px"
												@search="handleInstanceSearch"
												@blur="instanceBlur"
												:filter-option="false"
                                            >
                                                <a-select-option
                                                    v-for="(
                                                        inst, instI
                                                    ) in filteredInstances"
                                                    :key="
                                                        (instI + 9).toString(
                                                            36
                                                        ) + instI
                                                    "
                                                    :value="inst.id"
                                                >
                                                    {{ inst.name }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </a-col>
                                </a-row>
                            </div>
                        </div>

						<hr style="margin-left: -24px; margin-right: -24px" />
						<h6 class="mb-4 mt-4 text-dark">Project Location</h6>
						<hr style="margin-left: -24px; margin-right: -24px" />
						<div :style="$mq == 'sm' ? 'width:100%' : 'width:75%'">
							<a-row :gutter="16">
								<a-col :span="24">
									<div style="font-weight: bold;">Type full address including city below to search and place your project location marker</div>
									<div class="aC pb-4 pt-2 bg-white">
										<div class="dF aC w-full">
											<vue-google-autocomplete
												id="map2"
												classname="form-control f1"
												placeholder="Start typing to enter Development Address"
												v-on:placechanged="getAddressData"
											>
											</vue-google-autocomplete>
										</div>
									</div>
									<div id="map" style="height: 350px;"></div>

									<div class="mt-3 dF aC">
										<div style="font-weight: bold;">Choose marker image for your community</div>
										<a-tooltip class="ml-2" overlayClassName="change-tooltip-color">
											<template slot="title">
												For optimal resolution size to 300px x 300px. Save your file as either a PNG or JPEG no larger than 200KB
											</template>
											<a-icon type="question-circle" style="line-height: 25px; color: var(--orange);" />
										</a-tooltip>
									</div>

									<div class="relative mt-3">
										<div @click="mediaSelect" class="edit-current-marker2"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg></div>
										<div v-if="false" @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerIcon,type:'images'})" style="top: 3px;" class="edit-current-marker2"><i aria-label="icon: edit" class="anticon anticon-edit"><svg viewBox="64 64 896 896" data-icon="edit" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 0 0 0-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 0 0 9.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path></svg></i></div>
										<marker-selector @callback="updateMarkerIcon">
											<div class="image-holder-box-2">
												<span class="content">
												<div class="inside" :style="{ 'background-image': 'url(' + instance.locationOBJ.logo + ')' }" />
												</span>
											</div>
										</marker-selector>
									</div>
								</a-col>
							</a-row>
						</div>

						<hr style="margin-left: -24px; margin-right: -24px" />
						<h6 class="mb-4 mt-4 text-dark">Currency Settings</h6>
						<hr style="margin-left: -24px; margin-right: -24px" />
                        <a-row :gutter="16">
                            <a-col :span="12">
                                <a-form-model-item label="Currency">
                                    <a-select
                                        v-model="instance.currency"
										size="large"
                                    >
                                        <a-select-option value="USD"
                                            >United States Dollar
                                            ($)</a-select-option
                                        >
                                        <a-select-option value="CAD"
                                            >Canadian Dollar
                                            (CAD)</a-select-option
                                        >
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                    </a-form-model>

                    <hr style="margin-left: -24px; margin-right: -24px" />
                    <h6 class="mb-4 mt-4 text-dark">Measurement Settings</h6>
                    <hr style="margin-left: -24px; margin-right: -24px" />
                    <a-form-model
                        ref="measurementSettings"
                        :model="measurementSettings"
                    >
                        <a-row :gutter="16">
                            <a-col
                                :span="12"
                                v-if="
                                    selectedInstance.productType === 'lowrise'
                                "
                            >
                                <a-form-model-item>
                                    <div slot="label" class="dF aC">
                                        <div class="mr-1">
                                            Lot Frontage Size
                                        </div>
                                        <a-tooltip overlayClassName="change-tooltip-color">
                                            <template slot="title">
                                                This will allow you to set your
                                                site plan lot frontage to either
                                                feet or metres.
                                            </template>
                                            <a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 2px;" />
                                        </a-tooltip>
                                    </div>
                                    <a-select
                                        v-model="
                                            measurementSettings.frontageSize
                                        "
										size="large"
                                    >
                                        <a-select-option value="ft"
                                            >Feet (ft)</a-select-option
                                        >
                                        <a-select-option value="m"
                                            >Metre (m)</a-select-option
                                        >
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-model-item>
                                    <div slot="label" class="dF aC">
                                        <div class="mr-1">
                                            Home and Unit Measurements
                                        </div>
                                        <a-tooltip overlayClassName="change-tooltip-color">
                                            <template slot="title">
                                                This will determine if your
                                                model will be shown as feet or
                                                metres.
                                            </template>
                                            <a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 2px;" />
                                        </a-tooltip>
                                    </div>
                                    <a-select
                                        v-model="
                                            measurementSettings.generalMeasurement
                                        "
										size="large"
                                    >
                                        <a-select-option value="ft"
                                            >Feet (ft)</a-select-option
                                        >
                                        <a-select-option value="m"
                                            >Metre (m)</a-select-option
                                        >
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                    </a-form-model>
					<div v-if="instanceAPIKey">
						<hr style="margin-left: -24px; margin-right: -24px" />
						<h6 class="mb-4 mt-4 text-dark">API security</h6>
						<hr style="margin-left: -24px; margin-right: -24px" />

						<a-form-model>
							<a-row :gutter="16">
								<a-col :span="12" >
									<a-form-model-item>
										<template slot="label">
											API Key
											<a-tooltip placement="right" overlayClassName="change-tooltip-color">
												<template slot="title">
													In order to integrate Bildhive on external websites, you will need to use Bildhive's APIs. The Bildhive APIs are protected and require an API key in order to be used. Each project has its own unique API key.
												</template>
												<a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 2px;" />
											</a-tooltip>
										</template>
										<a-input :value="showAPIKey ? instanceAPIKey : '********************' + instanceAPIKey.substring(
													20
												)" size="large" disabled>
											<div slot="addonBefore" class="dF aC" style="gap: 5px">
												<a-icon type="copy" @click="copy(instanceAPIKey)" />
											</div>
											<div slot="addonAfter" class="dF aC" style="gap: 5px">
												<a-icon :type="showAPIKey ? 'eye-invisible' : 'eye'" @click="showAPIKey = !showAPIKey" />
											</div>
										</a-input>
									</a-form-model-item>
								</a-col>
							</a-row>
						</a-form-model>
					</div>

                    <div class="w-full mt-5">
                        <div
                            class="pull-left"
                            v-if="instance.billingOwner === user.user.id"
                        >
                            <a-button
                                @click="deleteModal = true"
                                type="danger"
                                ghost
                                ><a-icon type="delete" />DELETE</a-button
                            >
                        </div>

                        <div class="pull-right">
                            <a-button class="mr-3" @click="onClose"
                                >CANCEL</a-button
                            >
                            <a-button type="primary" @click="submit"
                                >SAVE SETTINGS</a-button
                            >
                        </div>
                    </div>
                </a-card>
            </div>
        </a-form>
        <a-modal
            v-model="deleteModal"
            title="Delete Your Project"
            ok-text="DELETE"
            @ok="onDelete"
            :ok-button-props="{ props: { disabled: !deleteAccepted } }"
        >
            <p>
                You are about to delete your project. All your data will be
                deleted.
            </p>
            <p>
                <b>Consider downloading your data before deleting a project.</b>
            </p>
            <a-checkbox v-model="downloadData">
                <span>
                    DOWNLOAD DATA (Your data will consist of all your leads and
                    all your completed transactions.)
                    <a-tooltip overlayClassName="change-tooltip-color">
                        <template slot="title">
                            Upon selecting the checkbox an email with a link
                            will be send to you that will allow you to download
                            your data.
                        </template>
                        <a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 2px;" />
                    </a-tooltip>
                </span>
            </a-checkbox>
            <br />
            <br />
            <a-checkbox v-model="deleteAccepted">
                I understand that deleting my project can’t be undone and all my
                data will be deleted.
            </a-checkbox>
        </a-modal>
        <a-modal
            v-model="colourModal.visible"
            title="Colors"
            @ok="handleColorChange"
        >
            <div class="dF aC" style="flex-wrap: wrap">
                <div
                    class="px-1 py-1"
                    v-for="(color, colorI) in colourList"
                    :key="color + colorI"
                    :value="color"
                >
                    <div
                        class="relative"
                        @click="selectedColor = color"
                        :style="{ 'background-color': color }"
                        style="
                            border-radius: 50%;
                            height: 20px;
                            width: 20px;
                            cursor: pointer;
                        "
                    >
                        <div
                            v-if="selectedColor === color"
                            class="absolute"
                            style="
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            "
                        >
                            <i class="fe fe-check" />
                        </div>
                    </div>
                </div>
            </div>
        </a-modal>
    </div>
</template>


<script>
import bhLoading from "bh-mod/components/common/Loading";
import * as maptalks from 'maptalks'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import MarkerSelector from '@/components/common/MarkerSelector'
import { gsap } from "gsap";
import { getCookie } from 'bh-mod'
import axios from 'axios'

export default {
    components: {
        bhLoading,VueGoogleAutocomplete,MarkerSelector
    },
    data: function () {
        return {
			filteredInstances: [],
            children: [],
            loading: false,
            deleteModal: false,
            deleteAccepted: false,
            downloadData: false,
			showAPIKey: false,
			instanceAPIKey: "",
            instanceID: "",
            instance: {
                name: "",
				marketingName: "",
                address: "",
                city: "",
                region: "",
                country: "",
                email: "",
                avatar: "",
                postalCode: "",
				currency: "USD",
				locationOBJ:{
					lat:null,
					lng:null,
					city:null,
					cityLabel:null,
					logo:'https://bildhive.nyc3.digitaloceanspaces.com/96c8782107aa4021b5bb02f4f50c2aa6.png'
				}
            },
            measurementSettings: {
                frontageSize: "",
                generalMeasurement: "",
            },
            instanceSettings: {},
            colourList: [
                "#FEC267",
                "#C1BCF4",
                "#89C6E5",
                "#7CDDC1",
                "#FCAFBA",
                "#3A558A",
                "#CD4386",
                "#E089B8",
                "#EF6D8B",
                "#FFDCE6",
                "#227949",
                "#9CB63E",
                "#C7E77A",
                "#18A1B1",
                "#58CAD5",
                "#F3AC46",
                "#FED762",
                "#0A5A93",
                "#83776B",
                "#BEB7AC",
                "#EEDDBF",
                "#F3F1EC",
                "#AE9898",
                "#5AF8E1",
                "#52C4FD",
                "#AB81FC",
                "#C3C3C5",
                "#FD395D",
                "#ACECF9",
                "#FECCAC",
                "#D18063",
                "#917B56",
                "#FDA28F",
                "#7FFD80",
                "#AAD7CF",
                "#D8ECE2",
                "#CBCFE0",
                "#7F7F7F",
                "#B2B2B2",
                "#E5E5E5",
            ],
            selectedColor: "",
            colourModal: {
                visible: false,
                instance: {},
            },

			map:null,
			selectAddress:false,
			point:null,
			infoWindow:null,
			vectorLayer:null,
			center:[-79.379041,43.815641]
        };
    },
    watch: {
        colourModal: {
            handler(val) {
                if (val.visible) {
                    const color = this.getBackground(val.instance);
                    if (color !== "") {
                        this.selectedColor = color;
                    }
                }
            },
            deep: true,
        },
    },
    computed: {
		projectStatus() {
            if (this.instance.deleted) return "Deleted";
            if (this.instance.susspended) return "Locked";
            return "Active";
        },

        instances() {
            let instances = this.$store.state.instances;
            instances = instances.filter((instance) => {
                return (
                    instance.role &&
                    instance.role.name === "bh_admin" &&
                    instance.id !== this.selectedInstance.id
                );
            });
            if (
                this.selectedInstance.id ===
                    this.$store.state.childrenList.instance &&
                this.$store.state.childrenList.children.length
            ) {
                let list = this.$store.state.childrenList.children;
                list.forEach((instance) => {
                    const find = instances.find((i) => i.id === instance.id);
                    if (!find) {
                        instances.push(instance);
                    }
                });
            }
            return instances;
        },
        selectedInstance() {
            return this.$store.state.editDrawer.instance;
        },
        storeInstances() {
            return this.$store.state.instances;
        },
        user() {
            return this.$store.state.user;
        },
        userSettings() {
            if (
                this.$store.state.user.user.settings != null &&
                Object.keys(this.$store.state.user.user.settings).length != 0
            ) {
                return this.$store.state.user.user.settings;
            } else return {};
        },
    },
    methods: {
		req: (msg) => ({ required: true, message: msg }),
		mediaSelect(){
			let self = this;
			this.$api.interceptors.request.use(
				async request => {
					request.headers["Authorization"] = `Bearer ${getCookie('__bhjt')}`
					request.url = request.url.replace(':instance', self.instanceID)
					return request
				},
				self.$store.commit('MEDIA_SELECT',{callback:self.selectedMarkerIcon,type:'images'}),
				error => {
					console.log('error...')
					return Promise.reject(error)
				}
			)
		},
		selectedMarkerIcon(url){
            this.instance.locationOBJ.logo = url.url
			this.$store.commit('UPDATE_CURRENT_INSTANCE', '')
        },
		updateMarkerIcon(data){
            this.instance.locationOBJ.logo = data
        },
		getAddressData({latitude,longitude,locality}){
            this.changeView(latitude,longitude)
            this.putMarker(latitude,longitude)
            // this.putInfo(latitude,longitude)
        },
		changeView(latitude,longitude) {
            this.map.animateTo({
				center: [longitude, latitude],
				zoom: 15,
				pitch: 0,
				bearing: 20
            }, {
            	duration: 1000
            });
            // setTimeout(() => {

            // }, 4000);
        },
		putMarker(latitude,longitude){
			if (this.vectorLayer) this.map.removeLayer(this.vectorLayer);

			// same point without altitude
			// var point0 = new maptalks.Marker(
			//   [longitude, latitude],
			// ).updateSymbol({
			//   markerOpacity : 0.5,
			//   markerFill : '#bbb'
			// });

			let self = this
			this.point = new maptalks.Marker(
				[longitude, latitude],
				{
					draggable : true,
					properties : {
						altitude : 5
					}
				}
			);

			this.vectorLayer = new maptalks.VectorLayer('vector',{
				enableAltitude : true,        // enable altitude
				altitudeProperty : 'altitude' // altitude property in properties, default by 'altitude'
			}).addTo(self.map);

			this.point.addTo(this.vectorLayer);

			this.point.setInfoWindow({
				//'autoOpenOn' : 'click',  //set to null if not to open window when clicking on map
				'single' : false,
				'width'  : 183,
				'height' : 105,
				'custom' : true,
				'dx' : -3,
				'dy' : -12,
				'content'   : '<div class="pop__content">' +
				'<div class="pop__title">Lat/Lng Info </div>' +
				`<div class="pop__time popup-lat-lng"> <span id="popup-lat">${latitude}</span> <br/><span id="popup-lng">${longitude}</span></div>` +
				'</div>'
			});
			this.instance.locationOBJ.lat = latitude
			this.instance.locationOBJ.lng = longitude

			this.point.openInfoWindow();


			// this.point.on('mouseenter', function (e) {
			//   console.log('e',e)
			// })
			this.point.on('dragend', function (e) {
				console.log('e',e)
				self.instance.locationOBJ.lat = e.coordinate.y
				self.instance.locationOBJ.lng = e.coordinate.x
				self.putMarker(e.coordinate.y,e.coordinate.x)

				// TweenLite.to(Cont,5,{val:NewVal,roundProps:"val",onUpdate:function(){
				//   document.getElementById("counter").innerHTML=Cont.val
				// }});
				// self.location.lat = e.coordinate.y
				// self.location.lng = e.coordinate.x

				if (document.querySelector('.popup-lat-lng')) {
				document.getElementById("popup-lat").innerHTML=self.instance.locationOBJ.lat
				document.getElementById("popup-lng").innerHTML=self.instance.locationOBJ.lng
				gsap.to(self.instance.locationOBJ.lat,{duration:2,val:e.coordinate.y,onUpdate:function(){
					document.getElementById("popup-lat").innerHTML=self.instance.locationOBJ.lat
				}})
				gsap.to(self.instance.locationOBJ.lng,{duration:2,val:e.coordinate.x,onUpdate:function(){
					document.getElementById("popup-lng").innerHTML=self.instance.locationOBJ.lng
				}})
				}


			})
		},

		instanceBlur(){
			this.filteredInstances = this.instances
		},
		handleInstanceSearch(value){
			if (value && value.length == 0){
				this.filteredInstances = this.instances
			} else {
				const inputValue = value.toLowerCase();

				this.filteredInstances = this.instances.filter(inst =>
					inst.name.toLowerCase().includes(inputValue)
				);
			}
		},
        colourInstance(instance) {
            this.colourModal.instance = instance;
            this.colourModal.visible = true;
        },

        handleColorChange() {
            if (this.selectedColor != "") {
                let options = {};
                if (
                    this.userSettings.options &&
                    Object.keys(this.userSettings.options).length != 0 &&
                    this.userSettings != null &&
                    Object.keys(this.userSettings).length != 0
                ) {
                    let storeOptions = JSON.parse(
                        JSON.stringify(this.userSettings.options)
                    );
                    Object.keys(storeOptions).forEach((key) => {
                        options[key] = storeOptions[key];
                    });
                    if (!options.colour) {
                        let colour = {};
                        options.colour = colour;
                        options.colour[this.colourModal.instance.id] =
                            this.selectedColor;
                    } else {
                        options.colour[this.colourModal.instance.id] =
                            this.selectedColor;
                    }
                } else {
                    let colour = {};
                    options.colour = colour;
                    options.colour[this.colourModal.instance.id] =
                        this.selectedColor;
                }
                this.$api
                    .post("/settings", { options: options })
                    .then(({ data }) => {
                        let user = JSON.parse(JSON.stringify(this.user.user));
                        user.settings = data;
                        this.$store.commit("UPDATE_USER", user);
                        this.colourModal.visible = false;
                        this.colourModal.instance = {};
                        this.selectedColor = "";
                    }).catch((err) => console.error(err));
            }
        },

        getBackground(instance) {
            const avatar = instance.avatar;
            const id = instance.id;
            let found = this.storeInstances.find((x) => x.id === id);
            if (found) {
                if (found.color) {
                    if (
                        this.userSettings.options &&
                        this.userSettings.options.colour &&
                        this.userSettings.options.colour[id]
                    ) {
                        return this.userSettings.options.colour[id];
                    } else if (
                        !this.userSettings.options ||
                        !this.userSettings.options.colour ||
                        !this.userSettings.options.colour[id] ||
                        this.userSettings.options.colour[id] === ""
                    ) {
                        return found.color;
                    }
                } else {
                    if (
                        this.userSettings.options &&
                        this.userSettings.options.colour &&
                        this.userSettings.options.colour[id]
                    ) {
                        return this.userSettings.options.colour[id];
                    } else if (avatar && typeof avatar === "string") {
                        return avatar;
                    } else return "#FEC267";
                }
            } else return "";
        },
        submit() {
            let sendObj = JSON.parse(JSON.stringify(this.instance));
            sendObj.avatar = this.instance.avatar;
            if (this.children.length !== 0) {
                sendObj.children = this.children;
            } else {
                sendObj.children = [];
            }

            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
					let locationData = null;
					if (this.instance.locationOBJ.lat == null || this.instance.locationOBJ.lng == null){
						return this.$message.error('Please select a project location')
					} else {
						locationData = await axios.get('https://nominatim.openstreetmap.org/reverse', {
							params: {
								lat: this.instance.locationOBJ.lat,
								lon: this.instance.locationOBJ.lng,
								format: 'json'
							},
						})
						if (locationData && locationData.data && locationData.data.address && locationData.data.address.city){
							sendObj.locationOBJ.city = locationData.data.address.city.toLowerCase().replace(/\s/g, '');
							sendObj.locationOBJ.cityLabel = locationData.data.address.city;
						}
					}
                    let settings = {
                        instance: {
                            options: {
                                measurement: {},
                            },
                        },
                    };

                    settings.instance.options.measurement.frontageSize =
                        this.measurementSettings.frontageSize;
                    settings.instance.options.measurement.generalMeasurement =
                        this.measurementSettings.generalMeasurement;

                    let url = `/instances/${this.instanceID}`;
					this.loading = true;
                    let self = this;
					console.log('Sending Object...', sendObj)
                    this.$api
                        .put(url, sendObj)
                        .then(({ data }) => {
                            if (data.id) {
                                if (!self.instances.instance) {
                                    self.instanceSettings.instance = {
                                        options: settings.instance.options,
                                    };
                                } else if (
                                    self.instanceSettings.instance &&
                                    self.instanceSettings.instance.options
                                ) {
                                    self.instanceSettings.instance.options =
                                        settings.instance.options;
                                }
                                self.$api
                                    .post(
                                        `/settings/${self.instanceID}`,
                                        self.instanceSettings.instance
                                    )
                                    .then(({ data }) => {}).catch((err) => console.error(err));
                                self.$notification["success"]({
                                    message: "Success",
                                    description:
                                        "You have successfully Updated your Project.",
                                });
                                if (
                                    data.children &&
                                    data.children.length !== 0
                                ) {
                                    let childrenId = [];
                                    data.children.forEach((child) => {
                                        childrenId.push(child.id);
                                    });
                                    data.children = childrenId;
                                }
                                self.$store.commit("CLOSE_EDIT_INSTANCE", data);
                                self.$router.push("/settings/projects");
                            }
                        })
                        .catch((err) => {
                            console.error("CONSOLING ERROR ", err);
                            self.$router.push("/settings/projects");
                        }).finally(() => self.loading = false);
                } else {
                    return false;
                }
            });
        },
        onClose() {
            this.$store.commit("CLOSE_EDIT_INSTANCE", "");
            this.$router.push("/settings/projects");
        },
        onDelete() {
            this.$api.delete(
                `/instances/${this.instance.id}?downloadData=${this.downloadData}`
            ).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			});
            for (var i = 0; i < this.instances.length; i++) {
                if (this.instances[i].id === this.instance.id) {
                    this.instances.splice(i, 1);
                }
            }
            this.deleteModal = false;
            this.$router.push("/settings/projects");
        },

		copy(value) {
			navigator.clipboard.writeText(value);
			this.$message.success("Copied");
		},
    },
    async created() {
		console.log('created')
		try {
			this.loading = true;
			let { data } = await this.$api.get(
				`/instances/${this.selectedInstance.id}`
			).catch((err) => {
				this.loading = false;
				console.error(err)
			});

			setTimeout(() => {
				let self = this
				self.map = new maptalks.Map('map', {
					center : self.center,
					zoom: 12,
					dragPitch: true,
					dragRotate: true,
					minZoom: 8,
					maxZoom: 16,
					dragRotatePitch: true,
					baseLayer: new maptalks.TileLayer('base', {
						urlTemplate: 'http://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
						subdomains: ['a', 'b', 'c', 'd'],
						attribution: '&copy; <a href="http://osm.org">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/">CARTO</a>'
					})
				})
			}, 1000);

			if (data.locationOBJ && data.locationOBJ.lat !== null && data.locationOBJ.lng !== null){
				setTimeout(() => {
					this.putMarker(data.locationOBJ.lat,data.locationOBJ.lng)
					this.changeView(data.locationOBJ.lat,data.locationOBJ.lng)
				}, 1000);
			} else {
				data.locationOBJ = {
					lat:null,
					lng:null,
					logo:'https://bildhive.nyc3.digitaloceanspaces.com/96c8782107aa4021b5bb02f4f50c2aa6.png'
				}
			}

			this.$store.commit("SET_CHILDRENLIST", data);
			this.filteredInstances = this.instances;
			this.instance = data;
			this.instanceAPIKey = (data.api && data.api.key) || null;
			this.instanceID = this.selectedInstance.id;
			this.loading = false;

			if (this.selectedInstance.id) {
				this.$api
					.get(`/settings/${this.selectedInstance.id}`)
					.then(({ data }) => {
						this.instanceSettings = data;
						if (
							data.instance === null ||
							!data.instance ||
							!data.instance.options ||
							!data.instance.options.measurement ||
							data.instance.options.measurement === ""
						) {
							this.measurementSettings.frontageSize = "ft";
							this.measurementSettings.generalMeasurement = "ft";
						} else if (data.instance.options.measurement) {
							this.measurementSettings.frontageSize =
								data.instance.options.measurement.frontageSize;
							this.measurementSettings.generalMeasurement =
								data.instance.options.measurement.generalMeasurement;
						}
					}).catch((err) => console.error(err));
			}
			if (
				this.selectedInstance.children &&
				this.selectedInstance.children.length !== 0
			) {
				this.children = this.selectedInstance.children.map(
					(child) => child.id
				);
			} else {
				this.children = [];
			}

			if (!data.currency) {
				this.instance.currency = 'USD'

				let sendObj = JSON.parse(JSON.stringify(this.instance));
				sendObj.avatar = this.instance.avatar;
				if (this.children.length !== 0) {
					sendObj.children = this.children;
				} else {
					sendObj.children = [];
				}

				this.$api.put(`/instances/${this.instanceID}`, sendObj).then(() => { }).catch((error) => {
					console.error('Error while updating currency', error)
				})
			}
		} catch (err) {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		}
	},
};
</script>

<style scoped>
.inherit-selection .ant-form-item-control-wrapper {
    flex: 1;
    margin-left: 15px;
}

.hexagon-main {
    position: relative;
    width: 120px;
    height: 120px;
    display: block;
    margin: auto;
    padding-left: 20px;
}

.hexagon {
    overflow: hidden;
    visibility: hidden;
    -webkit-transform: rotate(120deg);
    -moz-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    -o-transform: rotate(120deg);
    transform: rotate(120deg);
    cursor: pointer;
}
.hexagon-in1 {
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}
.hexagon-in2 {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    background-color: #d0c9d6;
    visibility: visible;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}
.hexagon2 {
    width: 100px;
    height: 200px;
}

.inherit-selection .ant-form-item-control-wrapper {
    flex: 1;
    margin-left: 15px;
}

.image-holder-box-2{
      display: table;
      width: 104px;
      height: 104px;
      text-align: center;
      vertical-align: top;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      cursor: pointer;
  }
  .image-holder-box-2:hover{
      border-color: #9693E7;
      transition: border-color .3s ease;
  }
  .image-holder-box-2 .content{
    display: table-cell;
    width: 100%;
    height: 100%;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
  }
  .image-holder-box-2 .content .inside{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    width: 100px;
    height: 100px;
  }
</style>
<style lang="scss">
.edit-current-marker2{
	i{
		pointer-events: none;
	}
	svg{
		pointer-events: none;
	}
	cursor: pointer;
	position: absolute;
	z-index: 10;
	left: 80px;
	top: 0;
	right: 0;
	background: white;
	padding: 10px;
	transform: translate(50%,-50%);
	border-radius: 50%;
	box-shadow: -1px 1px 4px rgba(0,0,0,0.3);
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover{
		background:white;
		color:var(--med-gray);
	}
}
</style>
